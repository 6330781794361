import React from "react";
// import styles from "/src/components/MovieSearch.module.css";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const SliderIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1805_467)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.13965 1.6005V0.840088C4.13965 0.641176 4.21867 0.45041 4.35932 0.309758C4.49997 0.169106 4.69074 0.0900879 4.88965 0.0900879C5.08856 0.0900879 5.27933 0.169106 5.41998 0.309758C5.56063 0.45041 5.63965 0.641176 5.63965 0.840088V1.60016C5.75664 1.63193 5.87192 1.67121 5.98475 1.71795C6.50735 1.93442 6.95402 2.30099 7.26828 2.77131C7.58254 3.24164 7.75027 3.79459 7.75027 4.36025C7.75027 4.73583 7.6763 5.10773 7.53257 5.45472C7.38884 5.80171 7.17818 6.117 6.9126 6.38257C6.64702 6.64815 6.33174 6.85881 5.98475 7.00254C5.8721 7.0492 5.75682 7.08851 5.63965 7.12032V15.22C5.63965 15.4189 5.56063 15.6096 5.41998 15.7503C5.27933 15.891 5.08856 15.97 4.88965 15.97C4.69154 15.9674 4.50228 15.8875 4.36218 15.7474C4.22209 15.6073 4.14224 15.4181 4.13965 15.22V7.11998C3.84301 7.0393 3.55971 6.91088 3.30134 6.73825C2.83102 6.42399 2.46445 5.97732 2.24798 5.45472C2.03151 4.93212 1.97488 4.35707 2.08523 3.80229C2.19558 3.2475 2.46797 2.7379 2.86795 2.33792C3.22116 1.98471 3.65985 1.731 4.13965 1.6005ZM4.64382 5.72767C4.66906 5.73232 4.69437 5.73626 4.71974 5.73947C4.77501 5.72661 4.83199 5.71997 4.88965 5.71997C4.94755 5.71997 5.00475 5.72667 5.06023 5.73963C5.18761 5.72361 5.31278 5.68956 5.43185 5.63791C5.67977 5.53036 5.89003 5.35138 6.0358 5.12382C6.18157 4.89626 6.25624 4.63043 6.25027 4.36025C6.24501 4.00394 6.09908 3.66417 5.84431 3.41503C5.58955 3.16588 5.24661 3.02757 4.89027 3.03025C4.62003 3.03031 4.35593 3.11089 4.13167 3.2617C3.90742 3.4125 3.73318 3.6267 3.63118 3.87696C3.52919 4.12722 3.50407 4.4022 3.55902 4.6668C3.61397 4.9314 3.74651 5.17362 3.93972 5.36258C4.13294 5.55153 4.37806 5.67863 4.64382 5.72767Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.52856 14.093C9.78443 14.2675 10.0655 14.3984 10.3604 14.482V15.2201C10.3604 15.419 10.4394 15.6098 10.58 15.7504C10.7207 15.8911 10.9114 15.9701 11.1104 15.9701C11.3085 15.9675 11.4977 15.8876 11.6378 15.7476C11.7779 15.6075 11.8578 15.4182 11.8604 15.2201V14.4982C11.9796 14.4672 12.0969 14.4285 12.2116 14.3821C12.5614 14.2408 12.8798 14.0316 13.1485 13.7667C13.4171 13.5018 13.6306 13.1864 13.7768 12.8386C13.923 12.4908 13.999 12.1175 14.0003 11.7402C14.0022 11.1754 13.8369 10.6227 13.5251 10.1517C13.2134 9.68077 12.7692 9.31265 12.2485 9.0938C12.1219 9.04059 11.9922 8.99679 11.8604 8.96249V0.840088C11.8604 0.641176 11.7813 0.45041 11.6407 0.309758C11.5 0.169106 11.3093 0.0900879 11.1104 0.0900879C10.9114 0.0900879 10.7207 0.169106 10.58 0.309758C10.4394 0.45041 10.3604 0.641176 10.3604 0.840088V8.97876C9.89854 9.10965 9.47573 9.35511 9.13209 9.69399C8.72995 10.0906 8.45423 10.5973 8.3397 11.1504C8.22516 11.7035 8.27695 12.2781 8.48852 12.8017C8.7001 13.3254 9.06198 13.7747 9.52856 14.093ZM10.8312 13.0733C10.8899 13.0851 10.9491 13.093 11.0083 13.0971C11.0419 13.0924 11.076 13.0901 11.1104 13.0901C11.1422 13.0901 11.1739 13.0921 11.2052 13.0961C11.3465 13.0852 11.4859 13.0522 11.6184 12.9976C11.8675 12.895 12.0805 12.7207 12.2303 12.4968C12.3802 12.2729 12.4603 12.0096 12.4603 11.7402C12.4603 11.3812 12.3184 11.0368 12.0655 10.7821C11.8126 10.5273 11.4692 10.3828 11.1103 10.3802C10.8409 10.3782 10.5769 10.4563 10.352 10.6045C10.127 10.7528 9.95114 10.9645 9.84667 11.2128C9.7422 11.4611 9.71384 11.7349 9.76518 11.9993C9.81652 12.2638 9.94525 12.5071 10.135 12.6983C10.3248 12.8895 10.5672 13.02 10.8312 13.0733Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1805_467">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0300293)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SliderIcon;
